<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="账号名称" prop="username">
        <el-input v-model.trim="form.username" />
      </el-form-item>
      <el-form-item label="账号密码" prop="password" v-if="!editFlag">
        <el-input v-model.trim="form.password" show-password type="password" />
      </el-form-item>
      <el-form-item label="用户昵称" prop="nickName">
        <el-input v-model.trim="form.nickName" type="text" />
      </el-form-item>
      <el-form-item label="真实姓名" prop="realName">
        <el-input v-model.trim="form.realName" type="text" />
      </el-form-item>
      <el-form-item label="手机号码" prop="phone">
        <el-input v-model.trim="form.phone" type="text" />
      </el-form-item>
      <el-form-item label="邮箱" prop="email">
        <el-input v-model.trim="form.email" />
      </el-form-item>
      <el-form-item label="用户性别" prop="sex">
        <el-radio-group v-model="form.sex">
          <el-radio :label="0">不详</el-radio>
          <el-radio :label="1">男</el-radio>
          <el-radio :label="2">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-radio-group v-model="form.status">
          <el-radio :label="false">正常</el-radio>
          <el-radio :label="true">停用</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="所属部门" prop="deptId">
        <selectTree :model-value="form.deptId" :options="departmentData" @nodeClick="departCheck" />
      </el-form-item>
      <el-form-item label="角色" prop="roleIdList">
        <el-select v-model="form.roleIdList" multiple placeholder="请选择角色">
          <el-option v-for="item in roleList" :key="item.id" :label="item.roleName" :value="item.id" />
        </el-select>
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input v-model.trim="form.remarks" type="text" />
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>
  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  defineAsyncComponent,
  reactive,
  toRefs,
} from 'vue'
import { doEdit, doAdd, getUserDetailByUid } from '@/api/userManagement'
import { getSelectListRole } from '@/api/roleManagement'
import { getDeptTree as getDepartList } from '@/api/departmentManagement'
import { useStore } from 'vuex'
// 引入下拉数选择
export default defineComponent({
  name: 'UserManagementEdit',
  components: {
    selectTree: defineAsyncComponent(() =>
      import('@/components/selectTree/selectTree')
    ),
  },
  emits: ['fetch-data'],
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()
    const store = useStore()
    // 自定义手机验证
    // const validatePhone = (rule, value, callback) => {
    //   const regExp =
    //     /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/
    //   if (!regExp.test(value)) {
    //     callback(new Error('请输入正确的手机号'))
    //   } else {
    //     callback()
    //   }
    // }
    const state = reactive({
      formRef: null,
      form: {
        roleIdList: [],
        deptId: '', //部门id
        nickName: '', //昵称
        password: '', //密码
        phone: '', //手机号码
        realName: '', //真实姓名,
        remarks: '', //备注
        sex: '', // 性别 1 男， 2女， 0不详
        status: '', // 0 正常，1 停用,
        username: '', //登录名
        isAdmin: false,
      },
      departmentData: [],
      rules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入用户名' },
        ],
        password: [{ required: true, trigger: 'blur', message: '请输入密码' }],
        email: [{ required: true, trigger: 'blur', message: '请输入邮箱' }],
        nickName: [{ required: true, trigger: 'blur', message: '请输入昵称' }],
        realName: [{ required: true, trigger: 'blur', message: '请输入真实姓名' }],
        phone: [{ required: true, trigger: 'blur', message: '请输入手机号' }],
        status: [{ required: true, trigger: 'change', message: '请选择状态' }],
        sex: [{ required: true, trigger: 'change', message: '请选择性别' }],
        roleIdList: [
          { required: true, trigger: 'change', message: '请选择角色' },
        ],
        deptId: [
          { required: true, trigger: 'change', message: '请选择部门' },
        ],
      },
      title: '',
      dialogFormVisible: false,
      roleList: [],
      query: {
        page: 1,
        pageSize: 999,
      },
      editFlag: false,
    })

    const showEdit = async (row) => {
      getRoleList()
      getDepart()
      if (!row) {
        state.title = '添加'
        state.editFlag = false
      } else {
        state.title = '编辑'
        state.editFlag = true
        getUserDetailData(row.uid)
      }
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        roles: [],
      }
      state.dialogFormVisible = false
    }
    // 获取全部角色
    const getRoleList = async () => {
      let { data } = await getSelectListRole()
      // data = data.filter((v) => v.roleKey != 'admin')
      state.roleList = data
    }

    // 获取全部部门列表
    const getDepart = async () => {
      const { data } = await getDepartList()
      state.departmentData = data
    }

    // 点击编辑获取用户详情
    const getUserDetailData = async (uid) => {
      const { data } = await getUserDetailByUid(uid)
      state.form = Object.assign({}, data)
      console.log(data, state.form)
    }

    // 选择部门列表
    const departCheck = (e) => {
      proxy.form.deptId = e.id
    }
    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let userInfo = store.getters['user/user']
          state.form.tid = userInfo.tid
          state.form.isAdmin = false
          let msg = ''
          if (state.title == '添加') {
            let data = await doAdd(state.form)
            msg = data.msg
          } else {
            let data = await doEdit(state.form)
            msg = data.msg
          }
          proxy.$baseMessage(msg, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    return {
      ...toRefs(state),
      showEdit,
      close,
      getRoleList,
      getDepart,
      departCheck,
      getUserDetailData,
      save,
    }
  },
})
</script>
